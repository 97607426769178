import InputGroup from "@/components/common/forms/InputGroup";

export function Textarea({ register, errors, name, placeholder, ...rest }) {
  const INPUT_VALIDATION = {
    required: true,
    minLength: {
      value: 1,
      message: `${name} must be at least 1 characters long`,
    }
  }

  return (
    <InputGroup name={name} errors={errors}>
      <textarea placeholder={placeholder} {...register(name, name !== "id" && INPUT_VALIDATION)} {...rest} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
    </InputGroup>
  )
}
