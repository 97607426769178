import { showPromiseConfirm } from '@/components/antd/modals/DeleteConfirmationModal';
import SectionHeading from '@/components/common/SectionHeading/SectionHeading';
import FormBox from '@/components/common/forms/FormBox';
import { EditActiveIcon, EditInactiveIcon } from '@/components/common/icons/Icons';
import PopupModal from '@/components/common/popupModal/PopupModal';
import ActionMenuDropdown from '@/components/headlessui/ActionMenuDropdown';
import useModals from '@/hooks/useModals';
import CredentialTable from '@/pages/credential/components/CredentialTable';
import { createNewCredential, deleteCredential, editCredential, selectCredentials, updateSelectedCredentialState } from '@/stores/credentialSlice';
import { CREDENTIAL_STATUS } from '@/variables/credentials';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ACTIONS } from 'variables/formActions';

const INITIAL_STATE = {
  create: false,
  edit: false,
  editPassword: false,
}

function CredentialPage({ name }) {
  const dispatch = useDispatch()
  const [selectedCredential, setSelectedCredential] = useState(null)
  const { rowsSelected, isLoading } = useSelector(selectCredentials)
  const { isModalOpen, closeModal, getModalHandler } = useModals(INITIAL_STATE)
  const [isSessionKeyChanged, setIsSessionKeyChanged] = useState(false)

  const modalCreate = getModalHandler(FORM_ACTIONS.TO_SHOW_CREATE_MODAL);
  const modalEdit = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_MODAL);
  const modalAddKey = getModalHandler(FORM_ACTIONS.TO_SHOW_ADD_KEY_MODAL);

  const handleActions = useCallback(
    (actionName, record) => {
      switch (actionName) {
        // HANDLE Show Modal
        case FORM_ACTIONS.TO_SHOW_ADD_KEY_MODAL:
          modalAddKey.open()
          setSelectedCredential(record)
          break
        case FORM_ACTIONS.TO_SHOW_EDIT_MODAL:
          modalEdit.open()
          setSelectedCredential(record)
          break
        case FORM_ACTIONS.TO_SHOW_DELETE_MODAL:
          showPromiseConfirm().then(({ ok, cancel }) => ok && dispatch(deleteCredential(record)))
          break

        // TOP-RIGHT BUTTON ACTIONS
        case FORM_ACTIONS.TO_SHOW_CREATE_MODAL:
          modalCreate.open()
          break

        // HANDLE SUBMIT TO API
        case FORM_ACTIONS.CREATE:
          dispatch(createNewCredential(record))
          modalCreate.close()
          break
        case FORM_ACTIONS.EDIT:
          dispatch(editCredential(record))
          modalEdit.close()
          break
        case FORM_ACTIONS.ADD_KEY:
          setIsSessionKeyChanged(prev => !prev)
          dispatch(updateSelectedCredentialState({ ...selectedCredential, ...record }))
          modalAddKey.close()
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsSelected, selectedCredential]
  )

  // Top-Right button ACTIONS
  const actions = [
    {
      name: 'Create',
      activeIcon: <EditActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      inActiveIcon: <EditInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      onClick: () => handleActions(FORM_ACTIONS.TO_SHOW_CREATE_MODAL),
    },
  ]

  return (
    <div className="space-y-5">
      <SectionHeading name={'Credentials'}>
        {/* <CustomDatePicker dispatchAction={updateSelectedDates}/> */}
        <ActionMenuDropdown actions={actions} />
      </SectionHeading>

      <CredentialTable handleActions={handleActions} isSessionKeyChanged={isSessionKeyChanged}/>

      <PopupModal
        formName={`${getModalHandler().nameOfCurrentModal()}${selectedCredential ? " #" + selectedCredential.id : ""}`}
        isModalOpen={isModalOpen}
        onClose={closeModal}
      >
      {(modalCreate.isOpen || modalEdit.isOpen) && (
        <FormBox
          onSubmit={(formValue) => 
            modalCreate.isOpen
              ? handleActions(FORM_ACTIONS.CREATE, formValue)
              : handleActions(FORM_ACTIONS.EDIT, formValue)
          }
          isLoading={isLoading}
          defaultValues={modalEdit && selectedCredential}
        >
          <FormBox.Input name="id" />
          <FormBox.Textarea name="key" />
          <FormBox.Textarea name="note" />
          <FormBox.Select name="status" options={CREDENTIAL_STATUS}  />
        </FormBox>
      )}
      {modalAddKey.isOpen && (
        <FormBox
          onSubmit={(formValue) => handleActions(FORM_ACTIONS.ADD_KEY, formValue)}
          isLoading={isLoading}
        >
          <FormBox.Textarea name="session_key" placeholder="sess-5bfXC1HwQHN7wp9IJVeLxu3khOJf6G184WyzaBFL"/>
        </FormBox>
      )}
      </PopupModal>
    </div>
  )
}

export default CredentialPage