import { message } from 'antd';


export const handleRejectedMessage = (state, action) => {
  let errorMessage
  // if (action?.error) {
  //   const errObject = JSON.parse(action?.error?.message)
  //   return message.error(`${errObject.error?.message}`)
  // }
  try {
    errorMessage = action.payload?.message || JSON.parse(action.error?.message)?.message
  } catch (error) {
    errorMessage = action.payload?.message || action.error?.message
  }

  if (errorMessage) {
    message.error(`${errorMessage}`)
  } else {
    message.error('An error occurred')
  }
}
export const handleFulfilledMessage = (state, action) => {
  message.success(action.payload.message)
}