import { handleAPIError } from '@/plugins/axiosHandler'
import axios from 'axios'

const no_auth_api = axios.create({
  baseURL: `https://api.brevo.com/v3`,
  headers: {
    "api-key": process.env.REACT_APP_BREVO_API_KEY
  }
})

export function makeBrevoRequestWithoutAuth(url, options) {
  return no_auth_api(url, options)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return handleAPIError(error)
    })
}


export default no_auth_api
