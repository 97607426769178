import { showPromiseConfirm } from '@/components/antd/modals/DeleteConfirmationModal';
import SectionHeading from '@/components/common/SectionHeading/SectionHeading';
import FormBox from '@/components/common/forms/FormBox';
import { DeleteActiveIcon, DeleteInactiveIcon, EditActiveIcon, EditInactiveIcon } from '@/components/common/icons/Icons';
import PopupModal from '@/components/common/popupModal/PopupModal';
import ActionMenuDropdown from '@/components/headlessui/ActionMenuDropdown';
import useModals from '@/hooks/useModals';
import TagTable from '@/pages/tag/components/TagTable';
import { createNewTag, deleteMultipleTags, deleteTag, editTag, selectTags } from '@/stores/tagSlice';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ACTIONS } from 'variables/formActions';

const INITIAL_STATE = {
  create: false,
  edit: false,
}

function TagPage({ name }) {
  const dispatch = useDispatch()
  const [selectedTag, setSelectedTag] = useState(null)
  const { paginator, rowsSelected, isLoading } = useSelector(selectTags)
  const { isModalOpen, closeModal, getModalHandler } = useModals(INITIAL_STATE)

  const modalCreate = getModalHandler(FORM_ACTIONS.TO_SHOW_CREATE_MODAL);
  const modalEdit = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_MODAL);

  const handleActions = useCallback(
    (actionName, record) => {
      switch (actionName) {
        // HANDLE Show Modal
        case FORM_ACTIONS.TO_SHOW_EDIT_MODAL:
          modalEdit.open()
          setSelectedTag(record)
          break
        case FORM_ACTIONS.TO_SHOW_DELETE_MODAL:
          showPromiseConfirm().then(({ ok, cancel }) => ok && dispatch(deleteTag(record)))
          break

        // TOP-RIGHT BUTTON ACTIONS
        case FORM_ACTIONS.TO_SHOW_CREATE_MODAL:
          modalCreate.open()
          break
        case FORM_ACTIONS.TO_SHOW_MULTI_DELETE_MODAL:
          showPromiseConfirm().then(({ ok, cancel }) => ok && dispatch(deleteMultipleTags({ ids: rowsSelected })))
          break

        // HANDLE SUBMIT TO API
        case FORM_ACTIONS.CREATE:
          dispatch(createNewTag(record))
          modalCreate.close()
          break
        case FORM_ACTIONS.EDIT:
          dispatch(editTag(record))
          modalEdit.close()
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsSelected, selectedTag]
  )

  // Top-Right button ACTIONS
  const actions = [
    {
      name: 'Create',
      activeIcon: <EditActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      inActiveIcon: <EditInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      onClick: () => handleActions(FORM_ACTIONS.TO_SHOW_CREATE_MODAL),
    },
    {
      name: `Delete ${rowsSelected.length} rows`,
      activeIcon: <DeleteActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      inActiveIcon: <DeleteInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      onClick: () => handleActions(FORM_ACTIONS.TO_SHOW_MULTI_DELETE_MODAL),
    },
  ]

  return (
    <div className="space-y-5">
      <SectionHeading name={'Tags'}>
      {/* <SearchBox
          title="Name"
          searchField="name"
          EntityPaginator={paginator}
          dispatchAction={fetchTagsBySuperAdmin}
        /> */}
        <ActionMenuDropdown actions={actions} />
      </SectionHeading>

      <TagTable handleActions={handleActions} />

      <PopupModal
        formName={`${getModalHandler().nameOfCurrentModal()}${selectedTag ? " #" + selectedTag.id : ""}`}
        isModalOpen={isModalOpen}
        onClose={closeModal}
      >
      {(modalCreate.isOpen || modalEdit.isOpen) && (
        <FormBox
          onSubmit={(formValue) => 
            modalCreate.isOpen
              ? handleActions(FORM_ACTIONS.CREATE, formValue)
              : handleActions(FORM_ACTIONS.EDIT, formValue)
          }
          isLoading={isLoading}
          defaultValues={modalEdit && selectedTag}
        >
          <FormBox.Input name="id" />
          <FormBox.Input name="name" />
        </FormBox>
      )}
      </PopupModal>
    </div>
  )
}

export default TagPage