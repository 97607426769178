export const convertArrObjToArrStr = ({ objectList, fieldName }) => {
  return objectList?.map((object) => object[fieldName])
}

export function formatNumberWithEvenCheck(num) {
  const formattedNumber = Number(num);
  return (formattedNumber % 2 === 0) ? formattedNumber.toFixed(0) : formattedNumber.toFixed(2);
}

export function isObjectNotEmpty(obj) {
  return Object.keys(obj).length > 0;
}

export function removeZeroValues(obj) {
  return Object.entries(obj)
    .filter(([key, value]) => value !== 0)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
}
