import InputGroup from "@/components/common/forms/InputGroup"

export function Select({ register, errors, options, name, ...rest }) {
  const SELECT_VALIDATION = {
    required: true,
    // minLength: {
    //   value: 0,
    //   message: `${name} must be at least min characters long`,
    // }
  }

  return (
    <InputGroup name={name} errors={errors}>
      <select {...register(name, SELECT_VALIDATION)} {...rest} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        {options && options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </InputGroup>
  )
}