import SectionHeading from '@/components/common/SectionHeading/SectionHeading';
import FormBox from '@/components/common/forms/FormBox';
import { EditActiveIcon, EditInactiveIcon } from '@/components/common/icons/Icons';
import PopupModal from '@/components/common/popupModal/PopupModal';
import SearchBox from '@/components/common/search/SearchBox';
import ActionMenuDropdown from '@/components/headlessui/ActionMenuDropdown';
import useModals from '@/hooks/useModals';
import ManagerTable from '@/pages/manager/components/ManagerTable';
import { createNewManager, editManager, selectManagers, editPasswordManager, signInPortal, fetchManagers } from '@/stores/managerSlice';
import { MANAGER_STATUSES } from '@/variables/managerStatus';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ACTIONS } from 'variables/formActions';

const INITIAL_STATE = {
  create: false,
  edit: false,
  edit_password: false
}

function ManagerPage({ name }) {
  const dispatch = useDispatch()
  const [selectedManager, setSelectedManager] = useState(null)
  const { paginator, rowsSelected, isLoading } = useSelector(selectManagers)
  const { isModalOpen, closeModal, getModalHandler } = useModals(INITIAL_STATE)
  const { hostname } = window.location;
  const domainName = hostname.split('.').slice(-2).join('.'); // Output: makergpt.ai

  const modalCreate = getModalHandler(FORM_ACTIONS.TO_SHOW_CREATE_MODAL);
  const modalEdit = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_MODAL);
  const modalEditPassword = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_PASSWORD_MODAL);

  const openBlankTabWithSignInToken = (managerToken) => {
    const newTab = window.open(process.env.REACT_APP_MAKER_GPT_FE_URL, '_blank')
    newTab.document.cookie = `authToken=${managerToken}; path=/; domain=${"." + domainName};`;
    newTab.focus()
  }

  const handlePortalBtnClicked = (inputManager) => {
    dispatch(signInPortal(inputManager)).then((response) => {
      if (response.payload) {
        const manager = response.payload.data
        return openBlankTabWithSignInToken(manager.access_token)
      }
    })
  }


  const handleActions = useCallback(
    (actionName, record) => {
      switch (actionName) {
        // HANDLE Show Modal
        case FORM_ACTIONS.TO_SHOW_EDIT_MODAL:
          modalEdit.open()
          setSelectedManager(record)
          break
        case FORM_ACTIONS.TO_SHOW_EDIT_PASSWORD_MODAL:
          modalEditPassword.open()
          break

        // TOP-RIGHT BUTTON ACTIONS
        case FORM_ACTIONS.TO_SHOW_CREATE_MODAL:
          modalCreate.open()
          break

        // HANDLE SUBMIT TO API
        case FORM_ACTIONS.CREATE:
          dispatch(createNewManager(record))
          modalCreate.close()
          break
        case FORM_ACTIONS.EDIT:
          dispatch(editManager(record))
          modalEdit.close()
          break
        case FORM_ACTIONS.EDIT_PASSWORD:
          dispatch(editPasswordManager(record))
          modalEditPassword.close()
          break
        case FORM_ACTIONS.SIGN_IN_PORTAL:
          handlePortalBtnClicked(record)
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsSelected, selectedManager]
  )

  const actions = [
    {
      name: 'Create',
      activeIcon: <EditActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      inActiveIcon: <EditInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      onClick: () => handleActions(FORM_ACTIONS.TO_SHOW_CREATE_MODAL),
    },
  ]

  return (
    <div className="space-y-5">
      <SectionHeading name={'Managers'}>
        <SearchBox
            title="Email"
            searchField="email"
            EntityPaginator={paginator}
            dispatchAction={fetchManagers}
        />
        <ActionMenuDropdown actions={actions} />
      </SectionHeading>

      <ManagerTable handleColumnClick={handleActions} />

      <PopupModal
        formName={`${getModalHandler().nameOfCurrentModal()}${selectedManager ? " #" + selectedManager.id : ""}`}
        isModalOpen={isModalOpen}
        onClose={closeModal}
      >
      {modalCreate.isOpen && (
        <FormBox
          onSubmit={(formValue) => handleActions(FORM_ACTIONS.CREATE, formValue)}
          isLoading={isLoading}
        >
          <FormBox.Input name="id" />
          <FormBox.CustomSelect name="agent_id" />
          <FormBox.Input name="email" />
          <FormBox.Input name="name" />
          <FormBox.Input name="password" />
        </FormBox>
      )}
      {modalEdit.isOpen && (
        <FormBox
          onSubmit={(formValue) => handleActions(FORM_ACTIONS.EDIT, formValue)}
          isLoading={isLoading}
          defaultValues={selectedManager}
        >
          <FormBox.Input name="id" />
          <FormBox.Input name="email" />
          <FormBox.Input name="name" />
          <FormBox.Select name="status" options={MANAGER_STATUSES} />
        </FormBox>
      )}
      {modalEditPassword.isOpen && (
        <FormBox
          onSubmit={(formValue) => handleActions(FORM_ACTIONS.EDIT_PASSWORD, formValue)}
          isLoading={isLoading}
          defaultValues={selectedManager}
        >
          <FormBox.Input name="id" />
          <FormBox.Input name="new_password" />
          <FormBox.Input name="password_confirmation" />
        </FormBox>
      )}
      
      </PopupModal>
    </div>
  )
}

export default ManagerPage