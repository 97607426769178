import Button from '@/components/common/buttons/Button'
import CustomSelect from '@/components/reactForm/CustomSelect'
import Form from '@/components/reactForm/Form'
import { Input } from '@/components/reactForm/Input'
import { Select } from '@/components/reactForm/Select'
import { Textarea } from '@/components/reactForm/Textarea'

FormBox.Input = Input
FormBox.Textarea = Textarea
FormBox.Select = Select
FormBox.CustomSelect = CustomSelect

export default function FormBox({
  onSubmit,
  defaultValues,
  isLoading,
  children
}) {

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      {children}
      <Button isLoading={isLoading} type="submit" btnName="Submit" />
    </Form>
  )
}
