export default function SectionHeading({ name, children }) {
  return (
    <div className="pb-5 border-b border-gray-200 flex sm:items-center sm:justify-between flex-col sm:flex-row space-y-6 sm:space-y-0">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{name}</h3>
      <div className="mt-3 sm:mt-0 sm:ml-4">
        <label htmlFor="mobile-search-candidate" className="sr-only">
          Search
        </label>
        <label htmlFor="desktop-search-candidate" className="sr-only">
          Search
        </label>
        <div className="flex rounded-md shadow-sm">{children}</div>
      </div>
    </div>
  )
}
