import LoadingCircle from '@/components/common/icons/LoadingCircle'

function Button({ isLoading, type, btnName }) {
  return (
    <button
      type={type}
      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative"
      disabled={isLoading}
    >
      <span className="mr-2">
        {isLoading ? (
          <div>
            <LoadingCircle isLoading={true} width='1.25rem' height='1.25rem' />
          </div>
        ) : (
          btnName
        )}
      </span>
    </button>
  )
}

export default Button
