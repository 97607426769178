import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space } from 'antd'

const { confirm } = Modal

export const showDeleteConfirm = () => {
  confirm({
    title: 'Are you sure delete this?',
    icon: <ExclamationCircleFilled />,
    content: 'Some descriptions',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      console.log('OK')
    },
    onCancel() {
      console.log('Cancel')
    },
  })
}

export const showPromiseConfirm = () => {
  return new Promise((resolve) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      icon: <ExclamationCircleFilled />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        resolve({ ok: true })
      },
      onCancel() {
        resolve({ cancel: true })
      },
    })
  })
}

// export default function DeleteConfirmationModal() {
//   <Space wrap>
//     {/* <Button onClick={showConfirm}>Confirm</Button> */}
//     <Button onClick={showDeleteConfirm} type="dashed">
//       Delete
//     </Button>
//   </Space>
// }
