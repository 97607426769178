import { EditOutlined, KeyOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AntdTable from '@/components/antd/table/AntdTable';
import { fetchManagers, selectManagers } from '@/stores/managerSlice';
import { FORM_ACTIONS } from '@/variables/formActions';


function ManagerTable({ handleColumnClick }) {
  let { managers, paginator, isLoading } = useSelector(selectManagers)
  const dispatch = useDispatch()

  // 1. user clicked sort || page button
  const handleTableChange = (pagination, filters, sorter) => {
    // overwrites the existing tableParams object with the new values
    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: sorter?.field,
      order: sorter?.order,
    }
    dispatch(fetchManagers(paginator))
  }

  useEffect(() => {
    dispatch(fetchManagers(paginator)) // Fetch for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'ManagerID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: true,
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'isVerify',
      key: 'is_verified',
      dataIndex: 'is_verified',
      // sorter: true,
      render: (is_verified) => (
        <Badge
          className="!flex items-center"
          status={`${is_verified ? 'success' : 'error'}`}
          text={is_verified ? "Verified" : "Not Verified"}
        />
      ),
    },
    {
      title: 'Agent Name',
      key: 'agent_name',
      dataIndex: 'agent_name',
      // sorter: true,
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Portal',
      align: 'center',
      render: (_, record) => (
        <Space size="middle" className="text-center">
          <Tooltip title="Sign in Portal">
            <UserSwitchOutlined
              className="text-indigo-500 text-lg"
              onClick={() => handleColumnClick(FORM_ACTIONS.SIGN_IN_PORTAL, record)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              className="text-indigo-500 text-lg"
              onClick={() => handleColumnClick(FORM_ACTIONS.TO_SHOW_EDIT_MODAL, record)}
            />
          </Tooltip>
          <Tooltip title="Edit Password">
            <KeyOutlined
              className="text-indigo-500 text-lg"
              onClick={() => handleColumnClick(FORM_ACTIONS.TO_SHOW_EDIT_PASSWORD_MODAL, record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <AntdTable
      columns={columns}
      dataSource={managers}
      paginator={paginator}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey="id"
    />
  )
}

export default ManagerTable