import { SearchIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoadingCircle from '@/components/common/icons/LoadingCircle'

function SearchBox({
  title,
  EntityPaginator,
  searchField = 'name',
  dispatchAction = (params) => {},
}) {
  const [inputValue, setInputValue] = useState('')
  const [initialSearchDone, setInitialSearchDone] = useState(false) // New flag
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (!initialSearchDone) {
      // Skip initial search
      return setInitialSearchDone(true)
    }

    setIsFetching(true)

    const debounceTimer = setTimeout(() => {
      const params = {
        ...EntityPaginator,
      }

      params[searchField] = inputValue

      dispatch(dispatchAction(params)).finally(() => setIsFetching(false))
    }, 1000)

    return () => {
      clearTimeout(debounceTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  return (
    <div className="relative flex-grow focus-within:z-10">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="mobile-search-candidate"
        id="mobile-search-candidate"
        className="capitalize placeholder:text-gray-400 placeholder:opacity-85 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
        placeholder={`${title || ''}`}
        onChange={handleInputChange}
      />
      <input
        type="text"
        name="desktop-search-candidate"
        id="desktop-search-candidate"
        className="capitalize placeholder:text-gray-400 placeholder:opacity-85 hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
        placeholder={`${title || ''}`}
        onChange={handleInputChange}
      />
      <div className="absolute right-0 top-0 pt-[0.7rem] pl-4">
        <LoadingCircle isDark isLoading={isFetching} width="1rem" height="1rem" />
      </div>
    </div>
  )
}

export default SearchBox
