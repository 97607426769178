import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'
import { makeRequestWithAuth } from '@/plugins/axios'

const initialState = {
  packages: [],
  paginator: null,
  isLoading: false,
  error: null,
}

export const fetchPackageBySuperAdmin = createAsyncThunk(
  'packageSlice/fetchPackageBySuperAdmin',
  (payload) => {
    const url = '/super_admin_packages'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

const packageSlice = createSlice({
  name: 'packageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPackageBySuperAdmin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPackageBySuperAdmin.fulfilled, (state, action) => {
      const name = action.meta.arg?.name
      state.isLoading = false
      state.packages = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        name: name,
      }
    })
    builder.addCase(fetchPackageBySuperAdmin.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
  },
})

export const selectPackages = (state) => {
  return {
    packages: state.package.packages,
    paginator: state.package.paginator,
    isLoading: state.package.isLoading,
  }
}
export default packageSlice.reducer
