import React, { useState } from 'react'

const ReadmoreBtn = ({ text, limit = 50 }) => {
  const [showMore, setShowMore] = useState(false)

  const handleToggle = () => {
    setShowMore(!showMore)
  }

  const truncatedText = showMore ? text : text.slice(0, limit)
  const showToggleButton = text.length > limit

  return (
    <p className="leading-relaxed">
      {truncatedText}
      {showToggleButton && (
        <span>
          {showMore ? '' : '... '}
          <button onClick={handleToggle} className="text-blue-500 focus:outline-none">
            {showMore ? 'Hide' : 'Read More'}
          </button>
        </span>
      )}
    </p>
  )
}

export default ReadmoreBtn
