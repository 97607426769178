import { Card, Col, Row, Statistic } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '@/components/common/pageTitle/PageTitle'
import { fetchAgentsBySuperAdmin, selectAgents } from '@/stores/agentSlice'
import { fetchManagers, selectManagers } from '@/stores/managerSlice'

export default function DashboardPage({ name }) {
  const { agents, paginator: agentPaginator } = useSelector(selectAgents)
  const { paginator: managerPaginator } = useSelector(selectManagers)
  const projectCounts = sumProjectsCount(agents)
  const dispatch = useDispatch()

  function sumProjectsCount(jsonData) {
    var projectsCountSum = 0
    for (var i = 0; i < jsonData?.data?.length; i++) {
      projectsCountSum += jsonData?.data[i].projects_count
    }
    return projectsCountSum
  }

  useEffect(() => {
    dispatch(fetchAgentsBySuperAdmin({}))
    dispatch(fetchManagers({}))
  }, [dispatch])

  return (
    <div>
      <PageTitle name={name} />

      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Agents"
              value={agentPaginator?.total}
              valueStyle={{ color: '#3f8600' }}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Managers"
              value={managerPaginator?.total}
              valueStyle={{ color: '#3f8600' }}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Projects"
              value={projectCounts}
              valueStyle={{ color: '#3f8600' }}
              // suffix="%"
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
