import { Table } from 'antd'

function AntdTable({ columns, dataSource, paginator, loading, onChange, rowKey = "id", scroll = { x: 'max-content' }, ...rest }) {
  return (
    <Table
      scroll={scroll}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        ...paginator,
        showSizeChanger: true,
      }}
      loading={loading}
      onChange={onChange}
      rowKey={rowKey}
      {...rest}
    />
  )
}

export default AntdTable
