import AntdTable from '@/components/antd/table/AntdTable';
import LoadingCircle from '@/components/common/icons/LoadingCircle';
import { timestampFormat } from '@/core/helpers/dayjs';
import { formatNumberWithEvenCheck } from '@/core/helpers/utils';
import useOnUpdateEffect from '@/hooks/useOnUpdateEffect';
import { editCredential, fetchCredentialsBySuperAdmin, fetchOpenAIAccountPlanCredit, fetchOpenAITotalCreditUsed, saveCredential, selectCredentials, updateCredentialsState } from '@/stores/credentialSlice';
import { CredentialStatus, CredentialStatusTrans } from '@/variables/credentials';
import { FORM_ACTIONS } from '@/variables/formActions';
import { DeleteOutlined, EditOutlined, KeyOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function CredentialTable({ handleActions, isSessionKeyChanged }) {
  let { credentials, paginator, isLoading, selectedDates } =
    useSelector(selectCredentials)
  const dispatch = useDispatch()
  let [startDate, endDate] = selectedDates
  startDate = !startDate ? dayjs().startOf('month').format('YYYY-MM-DD') : startDate;
  endDate = !endDate ? dayjs().startOf('month').add(1, 'month').format('YYYY-MM-DD') : endDate;

  const handleTableChange = (pagination, filters, sorter) => {
    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: sorter?.field,
      order: sorter?.order,
    }
    dispatch(fetchCredentialsBySuperAdmin(paginator))
  }

  useEffect(() => {
    dispatch(fetchCredentialsBySuperAdmin(paginator)) // Fetch for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchOpenAIAccountPlanCredit = async (key) => {
    if (!key) return Promise.reject()
    const res = await dispatch(fetchOpenAIAccountPlanCredit({ apiKey: key }))
    if (res.payload) {
      const { access_until, system_hard_limit_usd: planCredit } = res.payload
      return Promise.resolve({ access_until, planCredit })
    }
    if (res.error) {
      return Promise.reject(res.error.message)
    }
  }

  const handleFetchOpenAITotalCreditUsed = async (key) => {
    if (!key) return Promise.reject()
    const res = await dispatch(
      fetchOpenAITotalCreditUsed({ apiKey: key, selectedDates: selectedDates })
    )
    if (res.payload) {
      const { daily_costs, total_usage } = res.payload
      return Promise.resolve(total_usage)
    }
    if (res.error) {
      return Promise.reject(res.error.message)
    }
  }

  useOnUpdateEffect(() => {
    const getRemainingCreditsAfterCredentialLoaded = async () => {
      if (credentials.length > 0) {
        const promises = credentials.map(async (credential) => {
          try {
            const [{ access_until, planCredit }, totalCreditUsed] = await Promise.all([
              handleFetchOpenAIAccountPlanCredit(credential.session_key),
              handleFetchOpenAITotalCreditUsed(credential.session_key),
            ])
            return {
              ...credential,
              planCredit: planCredit,
              accessUntil: access_until,
              totalCreditUsed: totalCreditUsed / 100,
              remainingCredit: Math.max(planCredit - totalCreditUsed / 100, 0),
              errorMessage: null,
            }
          } catch (error) {
            return {
              ...credential,
              errorMessage: error,
            }
          }
        })

        Promise.allSettled(promises).then((updatedCredentials) => {
          // const statuses = updatedCredentials.map((result) => result.status)
          const values = updatedCredentials.map((result) => result.value)
          dispatch(updateCredentialsState(values))
          values.forEach(value => {
            if (value.session_key && value.errorMessage === null) { // check if session_key is provided 
              dispatch(saveCredential(value)) // Save fetched openai values to database
            }
          })
        })
      }
    }

    getRemainingCreditsAfterCredentialLoaded()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionKeyChanged])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (credentialId) => <div key={credentialId}>{credentialId}</div>,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      // width: 300,
      render: (key) => <div key={key}>{key}</div>,
    },
    {
      title: 'Plan Credit',
      dataIndex: 'planCredit',
      render: (planCredit, record) => (
        <div>
          <TextValue isLoading={isLoading} record={record} value={planCredit} />
        </div>
      ),
    },
    {
      title: `Total Credits (This Month)`,
      // ellipsis: true,
      // width: '40%',
      dataIndex: 'totalCreditUsed',
      render: (totalCreditUsed, record) => (
        <div>
          <TextValue isLoading={isLoading} record={record} value={totalCreditUsed} />
          <span className='block'>({startDate} - {endDate})</span>
        </div>
      ),
    },
    {
      title: 'Remaining',
      dataIndex: 'remainingCredit',
      render: (remainingCredit, record) => (
        <div>
          <TextValue isLoading={isLoading} record={record} value={remainingCredit} />
        </div>
      ),
    },
    {
      title: 'LastCheckedAt',
      dataIndex: 'checkedAt',
      render: (checkedAt) => (
        <div>{timestampFormat(checkedAt)}</div>
      ),
    },
    {
      title: 'AccessUntil',
      dataIndex: 'accessUntil',
      render: (accessUntil) => (
        <div>{timestampFormat(accessUntil)}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // sorter: true,
      render: (status) => (
        <Badge
          className="!flex items-center"
          status={`${status === CredentialStatus.ACTIVE ? 'success' : 'error'}`}
          text={CredentialStatusTrans(status)}
        />
      ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: 250,
      // sorter: true,
      render: (note) => <div key={note}>{note}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <KeyOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_ADD_KEY_MODAL, record)}
          />
          <EditOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_EDIT_MODAL, record)}
          />
          <DeleteOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_DELETE_MODAL, record)}
          />
        </Space>
      ),
    },
  ]

  return (
    <AntdTable
      columns={columns}
      dataSource={credentials}
      paginator={paginator}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey="id"
    />
  )
}

function TextValue({ record, value, isLoading }) {
  return (
    <LoadingCircle error={record?.errorMessage} width="1rem" height="1rem" isLoading={isLoading}>
      <span>${formatNumberWithEvenCheck(value)}</span>
    </LoadingCircle>
  )
}

export default CredentialTable