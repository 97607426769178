import { showPromiseConfirm } from '@/components/antd/modals/DeleteConfirmationModal';
import SectionHeading from '@/components/common/SectionHeading/SectionHeading';
import FormBox from '@/components/common/forms/FormBox';
import PopupModal from '@/components/common/popupModal/PopupModal';
import SearchBox from '@/components/common/search/SearchBox';
import useModals from '@/hooks/useModals';
import ProjectTable from '@/pages/project/components/ProjectTable';
import { createNewProject, deleteMultipleProjects, deleteProject, editProject, fetchProjectsBySuperAdmin, selectProjects } from '@/stores/projectSlice';
import { PRIVACY_SETTINGS } from '@/variables/project';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ACTIONS } from 'variables/formActions';

const INITIAL_STATE = {
  create: false,
  edit: false,
}

function ProjectPage({ name }) {
  const dispatch = useDispatch()
  const [selectedProject, setSelectedProject] = useState(null)
  const { paginator, rowsSelected, isLoading } = useSelector(selectProjects)
  const { isModalOpen, closeModal, getModalHandler } = useModals(INITIAL_STATE)

  const modalCreate = getModalHandler(FORM_ACTIONS.TO_SHOW_CREATE_MODAL);
  const modalEdit = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_MODAL);

  const handleActions = useCallback(
    (actionName, record) => {
      switch (actionName) {
        // HANDLE Show Modal
        case FORM_ACTIONS.TO_SHOW_EDIT_MODAL:
          modalEdit.open()
          setSelectedProject(record)
          break
        case FORM_ACTIONS.TO_SHOW_DELETE_MODAL:
          showPromiseConfirm().then(({ ok, cancel }) => ok && dispatch(deleteProject(record)))
          break

        // TOP-RIGHT BUTTON ACTIONS
        case FORM_ACTIONS.TO_SHOW_CREATE_MODAL:
          modalCreate.open()
          break
        case FORM_ACTIONS.TO_SHOW_MULTI_DELETE_MODAL:
          showPromiseConfirm().then(({ ok, cancel }) => ok && dispatch(deleteMultipleProjects({ ids: rowsSelected })))
          break

        // HANDLE SUBMIT TO API
        case FORM_ACTIONS.CREATE:
          dispatch(createNewProject(record))
          modalCreate.close()
          break
        case FORM_ACTIONS.EDIT:
          dispatch(editProject(record))
          modalEdit.close()
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsSelected, selectedProject]
  )

  return (
    <div className="space-y-5">
      <SectionHeading name={'Projects'}>
        <SearchBox
            title="name, description, ..."
            searchField="q"
            EntityPaginator={paginator}
            dispatchAction={fetchProjectsBySuperAdmin}
        />
      </SectionHeading>

      <ProjectTable handleActions={handleActions} />

      <PopupModal
        formName={`${getModalHandler().nameOfCurrentModal()}${selectedProject ? " #" + selectedProject.id : ""}`}
        isModalOpen={isModalOpen}
        onClose={closeModal}
      >
      {(modalCreate.isOpen || modalEdit.isOpen) && (
        <FormBox
          onSubmit={(formValue) => 
            modalCreate.isOpen
              ? handleActions(FORM_ACTIONS.CREATE, formValue)
              : handleActions(FORM_ACTIONS.EDIT, formValue)
          }
          isLoading={isLoading}
          defaultValues={modalEdit && selectedProject}
        >
          <FormBox.Input name="id" />
          <FormBox.Input name="name" />
          <FormBox.Input name="description" />
          <FormBox.Select name="privacy_setting" options={PRIVACY_SETTINGS} />
        </FormBox>
      )}
      </PopupModal>
    </div>
  )
}

export default ProjectPage