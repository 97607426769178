import { Helmet } from 'react-helmet'
import { Navigate, useLocation } from 'react-router-dom'

import { UNAUTHORIZED_REDIRECT_PATH } from '@/routes/index'

function PrivateRoute({ children, isLoggedIn, title }) {
  const location = useLocation()

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={UNAUTHORIZED_REDIRECT_PATH} state={{ from: location }} />
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}

export default PrivateRoute
