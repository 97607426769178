import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleFulfilledMessage, handleRejectedMessage } from '@/core/helpers/builderHandler'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'
import { makeRequestWithAuth } from '@/plugins/axios'
import { makeBrevoRequestWithoutAuth } from '@/plugins/axiosBrevo'

const initialState = {
  managers: [],
  paginator: null,
  isLoading: false,
  error: null,
  detail: null,
}

// =======================
// BREVO SLICE FUNCTION
// =======================
export const createNewBrevoContact = createAsyncThunk('managerSlice/createNewBrevoContact', ({email, name, ext_id}) => {
  const url = `/contacts`
  const data = {
    email: email,
    ext_id: toString(ext_id),
    attributes: {
      NAME: name
    }
  }
  const params = {}

  const options = {
    data: data,
    method: 'POST',
    params,
  }

  return makeBrevoRequestWithoutAuth(url, options)
})




// =======================
// MANAGER SLICE FUNCTION
// =======================

export const fetchManagers = createAsyncThunk('managerSlice/fetchManagers', (payload) => {
  const url = '/super_admin/managers'
  const data = null
  const params = mapPaginationFormat({ paginator: payload })

  const options = {
    method: 'GET',
    params,
    data,
  }

  return makeRequestWithAuth(url, options)
})

export const createNewManager = createAsyncThunk('managerSlice/createNewManager', (payload) => {
  const url = `/super_admin/managers`
  const data = {
    name: payload.name,
    email: payload.email,
    password: payload.password,
    agent_id: payload.agent_id,
  }
  const params = {}

  const options = {
    data: data,
    method: 'POST',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const editManager = createAsyncThunk('managerSlice/editManager', (payload) => {
  const url = `/super_admin/managers/${payload.id}`
  const data = { name: payload.name, status: parseInt(payload.status) }
  const params = {}

  const options = {
    data: data,
    method: 'PUT',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const editPasswordManager = createAsyncThunk(
  'managerSlice/editPasswordManager',
  (payload) => {
    const url = `/super_admin/managers/${payload.id}/update-password`
    const data = {
      new_password: payload.new_password,
      password_confirmation: payload.password_confirmation,
    }
    const params = {}

    const options = {
      data: data,
      method: 'PUT',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const signInPortal = createAsyncThunk('managerSlice/signInPortal', (payload) => {
  const url = `/super_admin/managers/sign_in_portal/`
  const params = {
    token: payload.token,
  }

  const options = {
    method: 'POST',
    params,
  }

  return makeRequestWithAuth(url, options)
})

const managerSlice = createSlice({
  name: 'managerSlice',
  initialState,
  reducers: {
    setManagerDetail: (state, action) => {
      state.detail = { ...state.detail, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    // Get Manager
    builder.addCase(fetchManagers.fulfilled, (state, action) => {
      const email = action.meta.arg?.email // email from request query params
      state.managers = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        email: email,
      }
    })
    builder.addCase(fetchManagers.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Create Brevo contact
    builder.addCase(createNewBrevoContact.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewBrevoContact.fulfilled, (state, action) => {
      state.isLoading = false
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewBrevoContact.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Create Manager
    builder.addCase(createNewManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewManager.fulfilled, (state, action) => {
      state.isLoading = false
      const createdManager = action.payload.data
      state.managers.unshift(createdManager)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewManager.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Manager
    builder.addCase(editManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editManager.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedManager = action.payload.data
      const managerIndex = state.managers?.findIndex((manager) => manager.id === updatedManager.id)
      if (managerIndex !== -1) {
        state.managers[managerIndex] = updatedManager
        handleFulfilledMessage(state, action)
      }
    })
    builder.addCase(editManager.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Manager Password
    builder.addCase(editPasswordManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editPasswordManager.fulfilled, (state, action) => {
      state.isLoading = false
      handleFulfilledMessage(state, action)
    })
    builder.addCase(editPasswordManager.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Sign in Portal
    builder.addCase(signInPortal.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(signInPortal.fulfilled, (state, action) => {
      state.isLoading = false
      handleFulfilledMessage(state, action)
    })
    builder.addCase(signInPortal.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })
  },
})

export const selectManagers = (state) => {
  return {
    managers: state.manager.managers,
    paginator: state.manager.paginator,
    isLoading: state.manager.isLoading,
    detail: state.manager.detail,
  }
}
export const { setManagerDetail } = managerSlice.actions
export default managerSlice.reducer
