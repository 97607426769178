import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { handleFulfilledMessage, handleRejectedMessage } from '@/core/helpers/builderHandler'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'

import { makeRequestWithAuth } from '@/plugins/axios'

const initialState = {
  projects: [],
  rowsSelected: [],
  paginator: null,
  isLoading: false,
  error: null,
}

export const fetchProjectsBySuperAdmin = createAsyncThunk(
  'projectSlice/fetchProjectsBySuperAdmin',
  (payload) => {
    const url = '/super_admin/projects'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const editProject = createAsyncThunk('projectSlice/editProject', (payload) => {
  const url = `/super_admin/projects/${payload.id}`
  const params = {}

  const options = {
    data: payload,
    method: 'PUT',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const createNewProject = createAsyncThunk(
  'projectSlice/createNewProject',
  (payload) => {
    const url = `/super_admin/projects`
    const data = { name: payload.name }
    const params = {}

    const options = {
      data: data,
      method: 'POST',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const deleteProject = createAsyncThunk(
  'projectSlice/deleteProject',
  (payload) => {
    const url = `/super_admin/projects/${payload.id}`
    const data = null
    const params = {}

    const options = {
      data: data,
      method: 'DELETE',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const deleteMultipleProjects = createAsyncThunk(
  'projectSlice/deleteMultipleProjects',
  ({ ids }) => {
    const url = `/super_admin/projects`
    const data = { ids: ids }
    const params = null

    const options = {
      data: data,
      method: 'DELETE',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setRowsSelected: (state, action) => {
      state.rowsSelected = action.payload
    },
  },
  extraReducers: (builder) => {
    // Get Project
    builder.addCase(fetchProjectsBySuperAdmin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProjectsBySuperAdmin.fulfilled, (state, action) => {
      const name = action.meta.arg?.name // name from request query params
      state.isLoading = false
      state.projects = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        name: name,
      }
    })
    builder.addCase(fetchProjectsBySuperAdmin.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Create Project
    builder.addCase(createNewProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewProject.fulfilled, (state, action) => {
      state.isLoading = false
      const createdProject = action.payload.data
      state.projects.unshift(createdProject)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewProject.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Project
    builder.addCase(editProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editProject.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedProject = action.payload.data
      const projectIndex = state.projects?.findIndex(
        (project) => project.id === updatedProject.id
      )
      if (projectIndex !== -1) {
        state.projects[projectIndex] = updatedProject
        handleFulfilledMessage(state, action)
      }
    })
    builder.addCase(editProject.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // DELETE
    builder.addCase(deleteProject.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.projects = state.projects.filter(project => project.id !== action.meta.arg.id)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // DELETE MULTI
    builder.addCase(deleteMultipleProjects.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMultipleProjects.fulfilled, (state, action) => {
      state.isLoading = false
      state.projects = state.projects.filter(project => !action.meta.arg.ids.includes(project.id));
      state.rowsSelected = []
      handleFulfilledMessage(state, action)
    })
    builder.addCase(deleteMultipleProjects.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })
  },
})

export const selectProjects = (state) => {
  return {
    projects: state.project.projects,
    rowsSelected: state.project.rowsSelected,
    paginator: state.project.paginator,
    isLoading: state.project.isLoading,
  }
}
export const { setRowsSelected } = projectSlice.actions
export default projectSlice.reducer
