import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { handleFulfilledMessage, handleRejectedMessage } from '@/core/helpers/builderHandler'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'

import { makeRequestWithAuth } from '@/plugins/axios'

const initialState = {
  tags: [],
  rowsSelected: [],
  paginator: null,
  isLoading: false,
  error: null,
}

export const fetchTagsBySuperAdmin = createAsyncThunk(
  'tagSlice/fetchTagsBySuperAdmin',
  (payload) => {
    const url = '/super_admin/tags'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const editTag = createAsyncThunk('tagSlice/editTag', (payload) => {
  const url = `/super_admin/tags/${payload.id}`
  const data = { name: payload.name }
  const params = {}

  const options = {
    data: data,
    method: 'PUT',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const createNewTag = createAsyncThunk(
  'tagSlice/createNewTag',
  (payload) => {
    const url = `/super_admin/tags`
    const data = { name: payload.name }
    const params = {}

    const options = {
      data: data,
      method: 'POST',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const deleteTag = createAsyncThunk(
  'tagSlice/deleteTag',
  (payload) => {
    const url = `/super_admin/tags/${payload.id}`
    const data = null
    const params = {}

    const options = {
      data: data,
      method: 'DELETE',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const deleteMultipleTags = createAsyncThunk(
  'tagSlice/deleteMultipleTags',
  ({ ids }) => {
    const url = `/super_admin/tags`
    const data = { ids: ids }
    const params = null

    const options = {
      data: data,
      method: 'DELETE',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

const tagSlice = createSlice({
  name: 'tagSlice',
  initialState,
  reducers: {
    setRowsSelected: (state, action) => {
      state.rowsSelected = action.payload
    },
  },
  extraReducers: (builder) => {
    // Get Tag
    builder.addCase(fetchTagsBySuperAdmin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchTagsBySuperAdmin.fulfilled, (state, action) => {
      const name = action.meta.arg?.name // name from request query params
      state.isLoading = false
      state.tags = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        name: name,
      }
    })
    builder.addCase(fetchTagsBySuperAdmin.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Create Tag
    builder.addCase(createNewTag.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewTag.fulfilled, (state, action) => {
      state.isLoading = false
      const createdTag = action.payload.data
      state.tags.unshift(createdTag)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewTag.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Tag
    builder.addCase(editTag.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editTag.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedTag = action.payload.data
      const tagIndex = state.tags?.findIndex(
        (tag) => tag.id === updatedTag.id
      )
      if (tagIndex !== -1) {
        state.tags[tagIndex] = updatedTag
        handleFulfilledMessage(state, action)
      }
    })
    builder.addCase(editTag.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // DELETE
    builder.addCase(deleteTag.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.isLoading = false
      state.tags = state.tags.filter(tag => tag.id !== action.meta.arg.id)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(deleteTag.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // DELETE MULTI
    builder.addCase(deleteMultipleTags.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMultipleTags.fulfilled, (state, action) => {
      state.isLoading = false
      state.tags = state.tags.filter(tag => !action.meta.arg.ids.includes(tag.id));
      state.rowsSelected = []
      handleFulfilledMessage(state, action)
    })
    builder.addCase(deleteMultipleTags.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })
  },
})

export const selectTags = (state) => {
  return {
    tags: state.tag.tags,
    rowsSelected: state.tag.rowsSelected,
    paginator: state.tag.paginator,
    isLoading: state.tag.isLoading,
  }
}
export const { setRowsSelected } = tagSlice.actions
export default tagSlice.reducer
