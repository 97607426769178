export const ProjectStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
}

export const PROJECT_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
}

export function ProjectStatusTrans(credential = 0) {
  return (
    {
      0: 'Active',
      1: 'InActive',
    }[credential] || ''
  )
}

export const PRIVACY_SETTINGS = [
  {
    label: 'Public',
    value: 0,
  },
  {
    label: 'Private',
    value: 1,
  },
]

export function PrivacySettingsTrans(privacySetting = 0) {
  return (
    {
      0: 'Public',
      1: 'Private',
    }[privacySetting] || ''
  )
}