import { useEffect, useState } from 'react';

function useModals(initialState) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [myModals, setMyModals] = useState(initialState);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  }

  const getModalHandler = (modalName) => {
    return {
      isOpen: myModals[modalName],
      open: () => {
        setMyModals((state) => ({ ...state, [modalName]: true }))
        openModal()
      },
      close: () => {
        setMyModals((state) => ({ ...state, [modalName]: false }))
        closeModal()
      },
      toggle: () =>
        setMyModals((state) => ({ ...state, [modalName]: !state[modalName] })),
      nameOfCurrentModal: () => 
        Object.keys(myModals).find(key => myModals[key] === true) || null
    };
  };

  useEffect(() => {
    // If close modal => set to default state
    setTimeout(() => {
      if (isModalOpen === false) setMyModals(initialState);
    }, 200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return {
    isModalOpen,
    openModal,
    closeModal,
    myModals, 
    setMyModals,
    getModalHandler
  };
}

export default useModals;
