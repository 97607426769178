import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(relativeTime);

function RelativeTime({ created_at }) {
    const localTime = dayjs(created_at).from(created_at);

  return <div>{localTime}</div>
}

export default RelativeTime
