export const MANAGER_STATUSES = [
  {
    label: 'Active',
    value: 0,
  },
  {
    label: 'Delete',
    value: 1,
  },
  {
    label: 'Banned',
    value: 2,
  },
]