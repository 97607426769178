import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { timestampFormat } from '@/core/helpers/dayjs'
import { convertArrObjToArrStr } from '@/core/helpers/utils'
import AntdTable from '@/components/antd/table/AntdTable'
import { fetchTagsBySuperAdmin, selectTags, setRowsSelected } from '@/stores/tagSlice'
import { FORM_ACTIONS } from '@/variables/formActions'

function TagTable({ handleActions }) {
  let { tags, paginator, isLoading } = useSelector(selectTags)
  const dispatch = useDispatch()

  // 1. user clicked sort || page button
  const handleTableChange = (pagination, filters, sorter) => {
    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: sorter?.field,
      order: sorter?.order,
    }
    dispatch(fetchTagsBySuperAdmin(paginator))
  }

  useEffect(() => {
    dispatch(fetchTagsBySuperAdmin(paginator)) // Fetch for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (tagId) => <div key={tagId}>{tagId}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      // sorter: true,
      render: (name) => <div key={name}>{name}</div>,
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      // sorter: true,
      render: (updatedAt) => <div key={updatedAt}>{timestampFormat(updatedAt)}</div>,
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      // sorter: true,
      render: (createdAt) => <div key={createdAt}>{timestampFormat(createdAt)}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_EDIT_MODAL, record)}
          />
          <DeleteOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_DELETE_MODAL, record)}
          />
        </Space>
      ),
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(
        setRowsSelected(convertArrObjToArrStr({ objectList: selectedRows, fieldName: 'id' }))
      )
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <AntdTable
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      dataSource={tags}
      paginator={paginator}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey="id"
    />
  )
}

export default memo(TagTable)
