import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import classNames from '@/core/helpers/classNames';
import removeUnderscore from '@/core/helpers/removeUnderscore';
import LoadingCircle from '@/components/common/icons/LoadingCircle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgentsByScrollingDown, fetchAgentsBySuperAdmin, selectAgents } from '@/stores/agentSlice';
import InputGroup from '@/components/common/forms/InputGroup';


export default function CustomSelect({ register, errors, options, name, ...rest }) {
  const { agents, paginator: agentPaginator, isLoading: isFetching } = useSelector(selectAgents)
  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAgentsBySuperAdmin({}))
  }, [dispatch])

  const [selectedEntity, setSelectedEntity] = useState(agents[0])

  const [query, setQuery] = useState('')

  // Filter by array of fields
  const filterEntities = (query, entities, filterFields) => {
    if (query === '') {
      return entities
    } else {
      return entities.filter((entity) => {
        const searchValue = query.toLowerCase().replace(/\s+/g, '')
        return filterFields.some((field) => {
          const fieldValue = String(entity[field] || '')
          return fieldValue.toLowerCase().replace(/\s+/g, '').includes(searchValue)
        })
      })
    }
  }

  const filterFields = ["agent_id", "name"]
  const displayField = "name"
  const selectedField = "agent_id"

  const filteredEntities = filterEntities(query, agents, filterFields)

  const [scrollPosition, setScrollPosition] = useState(0)
  const tolerance = 1 // Adjust this value as needed
  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    const scrollDifference = Math.abs(scrollHeight - scrollTop - clientHeight)

    if (scrollDifference <= tolerance && scrollTop > scrollPosition) {
      // User has scrolled to the bottom
      if (agentPaginator?.currentPage < agentPaginator?.pageCount) {
        // Fetch next page
        fetchAgentsByScrollingDown()
      }
    }
  }

  return (
    <InputGroup name={name} errors={errors}>
      <Combobox  {...rest} as="div" value={selectedEntity} onChange={setSelectedEntity}>
        <div className="relative mt-1">
          <Combobox.Input
            {...register(name)}
            className="w-full rounded-md border border-gray-300 pl-3 pr-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(entity) => entity?.agent_id}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {filteredEntities.length > 0 && (
            <Combobox.Options
              onScroll={handleScroll}
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <div className="pl-10 flex items-center justify-between bg-gray-100 py-2 px-4 text-gray-600">
                <span className="capitalize font-semibold">{removeUnderscore(selectedField)}</span>
                <span className="capitalize font-semibold ">{displayField}</span>
              </div>
              {filteredEntities.map((entity, index) => (
                <Combobox.Option
                  key={index}
                  value={entity}
                  className={({ active }) =>
                    classNames('relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="ml-2 flex items-center justify-between">
                        <span className={classNames('block truncate', selected && 'font-semibold')}>
                          {entity?.[selectedField]}
                        </span>
                        <span className={classNames('block truncate', selected && 'font-semibold')}>
                          {entity?.[displayField]}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
              <LoadingCircle
                className="flex justify-center my-2"
                isLoading={isFetching}
                width="2rem"
                height="2rem"
              />
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </InputGroup>
  )
}