import { Helmet } from 'react-helmet'
import { Navigate } from 'react-router-dom'

import { DEFAULT_PATH } from '@/routes/index'

function PublicRoute({ children, isLoggedIn, shouldRedirectIfLoggedin, title }) {
  if (isLoggedIn && shouldRedirectIfLoggedin) {
    // we dont want user to see the login page
    // if they're already loggedin
    // Navigate them to admin dashboard
    return <Navigate to={DEFAULT_PATH} />
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}

export default PublicRoute
