import { configureStore } from '@reduxjs/toolkit'
import agentReducer from './agentSlice'
import authenticatorReducer from './authenticatorSlice'
import credentialReducer from './credentialSlice'
import managerReducer from './managerSlice'
import packageReducer from './packageSlice'
import projectReducer from './projectSlice'
import tagReducer from './tagSlice'

export default configureStore({
  reducer: {
    authenticator: authenticatorReducer,
    agent: agentReducer,
    manager: managerReducer,
    credential: credentialReducer,
    tag: tagReducer,
    project: projectReducer,
    package: packageReducer,
  },
})
