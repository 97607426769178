import useModals from '@/hooks/useModals'
import { DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_ACTIONS } from 'variables/formActions'
import AgentTable from '@/pages/agent/components/AgentTable'
import { timestampFormat } from '@/core/helpers/dayjs'
import SectionHeading from '@/components/common/SectionHeading/SectionHeading'
import FormBox from '@/components/common/forms/FormBox'
import { EditActiveIcon, EditInactiveIcon } from '@/components/common/icons/Icons'
import PopupModal from '@/components/common/popupModal/PopupModal'
import SearchBox from '@/components/common/search/SearchBox'
import ActionMenuDropdown from '@/components/headlessui/ActionMenuDropdown'
import {
  createNewAgent,
  editAgent,
  fetchAgentsBySuperAdmin,
  selectAgents,
} from '@/stores/agentSlice'
import { fetchPackageBySuperAdmin, selectPackages } from '@/stores/packageSlice'

const INITIAL_STATE = {
  create: false,
  edit: false,
}

function AgentPage({ name }) {
  const dispatch = useDispatch()
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [periodEnd, setPeriodEnd] = useState(null)
  const { paginator, agents, isLoading } = useSelector(selectAgents)
  const { packages } = useSelector(selectPackages)
  const { isModalOpen, closeModal, getModalHandler } = useModals(INITIAL_STATE)
  const [selectedPackage, setSelectPackage] = useState('')
  const modalCreate = getModalHandler(FORM_ACTIONS.TO_SHOW_CREATE_MODAL)
  const modalEdit = getModalHandler(FORM_ACTIONS.TO_SHOW_EDIT_MODAL)

  useEffect(() => {
    dispatch(fetchPackageBySuperAdmin({ status: true, order: 1, order_by: 'kind' }))
  }, [])

  useEffect(() => {
    if (selectedAgent && selectedAgent.package) {
      setSelectPackage(selectedAgent.package.kind);
    }
  }, [selectedAgent])

  const handleActions = useCallback(
    (actionName, record) => {
      switch (actionName) {
        // HANDLE Show Modal
        case FORM_ACTIONS.TO_SHOW_EDIT_MODAL:
          modalEdit.open()
          setSelectedAgent(record)
          break

        // TOP-RIGHT BUTTON ACTIONS
        case FORM_ACTIONS.TO_SHOW_CREATE_MODAL:
          modalCreate.open()
          setSelectedAgent(null)
          break

        // HANDLE SUBMIT TO API
        case FORM_ACTIONS.CREATE:
          dispatch(createNewAgent(record))
          modalCreate.close()
          break
        case FORM_ACTIONS.EDIT:
          dispatch(editAgent(record))
          dispatch(fetchAgentsBySuperAdmin(paginator))
          modalEdit.close()
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAgent]
  )

  // Top-Right button ACTIONS
  const actions = [
    {
      name: 'Create',
      activeIcon: <EditActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      inActiveIcon: <EditInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
      onClick: () => handleActions(FORM_ACTIONS.TO_SHOW_CREATE_MODAL),
    },
  ]

  const onChange = (value, dateString) => {
    const periodEnd = dayjs(dateString, 'DD/MM/YYYY HH:mm').valueOf()
    setPeriodEnd(periodEnd)
  }

  const onOk = (value, dateString) => {
    console.log('onOk: ', value)
  }

  return (
    <div className="space-y-5">
      <SectionHeading name={'Agents'}>
        <SearchBox
          title="Name"
          searchField="name"
          EntityPaginator={paginator}
          dispatchAction={fetchAgentsBySuperAdmin}
        />
        <ActionMenuDropdown actions={actions} />
      </SectionHeading>

      <AgentTable handleActions={handleActions} />

      <PopupModal
        formName={`${getModalHandler().nameOfCurrentModal()}${
          selectedAgent ? ' #' + selectedAgent.agent_id : ''
        }`}
        isModalOpen={isModalOpen}
        onClose={closeModal}
      >
        {(modalCreate.isOpen || modalEdit.isOpen) && (
          <FormBox
            onSubmit={(formValue) => {
              periodEnd && (formValue.period_end = periodEnd)
              selectedPackage !== null && (formValue.kind = selectedPackage)
              modalCreate.isOpen
                ? handleActions(FORM_ACTIONS.CREATE, formValue)
                : handleActions(FORM_ACTIONS.EDIT, formValue)
            }}
            isLoading={isLoading}
            defaultValues={modalEdit && selectedAgent}
          >
            <FormBox.Input name="name" />
            <label className="block text-sm font-medium text-gray-700">Subscription Plan</label>
            <Select
              showSearch
              style={{ width: '100%', marginTop: '6px' }}
              placeholder="Please select a package"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              defaultValue={selectedAgent?.package?.kind ?? 3}
              onChange={(value) => {
                setSelectPackage(value)
              }}
            >
              {packages && packages.length
                ? packages.map((option) => (
                    <Select.Option key={option.kind} value={option.kind}>
                      {option.name}
                    </Select.Option>
                  ))
                : []}
            </Select>
            {selectedPackage === 0 || selectedPackage === 1 || selectedPackage === 2 ? (
              <div></div>
            ) : (
              <FormBox.Input
                name="credits"
                defaultValue={
                  selectedAgent?.balance_credit !== undefined
                    ? Number(selectedAgent.balance_credit).toFixed(2)
                    : 250
                }
              />
            )}
          </FormBox>
        )}
      </PopupModal>
    </div>
  )
}

export default AgentPage
