export const FORM_ACTIONS = {
  ADD_KEY: 'submit_add_key',
  TO_SHOW_ADD_KEY_MODAL: 'add_key',

  CREATE: 'submit_create',
  TO_SHOW_CREATE_MODAL: 'create',

  EDIT: 'submit_edit',
  TO_SHOW_EDIT_MODAL: 'edit',

  EDIT_PASSWORD: 'submit_edit_password',
  TO_SHOW_EDIT_PASSWORD_MODAL: 'edit_password',

  DELETE: 'submit_delete',
  TO_SHOW_DELETE_MODAL: 'delete',

  MULTI_DELETE: 'submit_multi_delete',
  TO_SHOW_MULTI_DELETE_MODAL: 'multi_delete',

  SIGN_IN_PORTAL: 'submit_sign-in-portal',
}
