import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import Cookies from 'js-cookie'
import { handleRejectedMessage } from '@/core/helpers/builderHandler'
import { makeRequestWithAuth, makeRequestWithoutAuth } from '@/plugins/axios'

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  error: null,
  user: null,
}

export const validateAuthTokenMiddleware = createAsyncThunk(
  'authenticatorSlice/validateAuthTokenMiddleware',
  (payload) => {
    const url = 'super_admins/profile'
    const options = {
      method: 'GET',
    }

    return makeRequestWithAuth(url, options)
  }
)

export const signIn = createAsyncThunk('authenticatorSlice/signIn', ({ email, password }) => {
  const url = 'super_admin_authentications/sign_in'
  const data = { email: email, password: password }
  const options = {
    data: data,
    method: 'POST',
  }

  return makeRequestWithoutAuth(url, options)
})

const authenticatorSlice = createSlice({
  name: 'authenticatorSlice',
  initialState,
  reducers: {
    signOut: (state) => {
      Cookies.remove('sadminAuthToken')
      state.isLoggedIn = false
      state.isLoading = false
      state.error = null
      message.success('Sign out successfully')
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLoading = false
      state.isLoggedIn = true
      Cookies.set('sadminAuthToken', action.payload.data.access_token)
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.isLoading = false
      state.isLoggedIn = false
      handleRejectedMessage(state, action)
    })

    builder.addCase(validateAuthTokenMiddleware.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(validateAuthTokenMiddleware.fulfilled, (state, action) => {
      state.isLoading = false
      state.isLoggedIn = true
      state.user = action.payload
    })
    builder.addCase(validateAuthTokenMiddleware.rejected, (state, action) => {
      Cookies.remove('sadminAuthToken')
      state.isLoading = false
      state.isLoggedIn = false
      state.error = action.payload
      handleRejectedMessage(state, action)
    })
  },
})

export const { signOut } = authenticatorSlice.actions
export default authenticatorSlice.reducer
