export const snakeToCamel = (str) => {
  if (!str) return

  if (typeof str !== 'string') {
    throw new Error('Input must be a string')
  }

  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
}

// Convert to frontend and backend format
export const mapPaginationFormat = ({ paginator }) => {
  if (!paginator) return
  const { current, pageSize, order, ...rest } = paginator
  const page = current || rest.page
  const limit = pageSize || rest.limit

  return {
    ...rest,
    page,
    limit,
    order: order === undefined ? -1 : (order === 'descend' ? -1 : 1),
    order_by: paginator.order_by,
    current: page,
    pageSize: limit,
  }
}
