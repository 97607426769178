import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useRef } from 'react'

export default function ActionMenuDropdown({ actions, shouldOpen }) {
  const menuButtonRef = useRef(null)

  useEffect(() => {
    if (shouldOpen && menuButtonRef.current) {
      menuButtonRef.current?.click();
    }
  }, [shouldOpen]);

  return (
    <div>
      <Menu as="div" className="mx-4 z-[1] relative inline-block text-left">
        <div>
          <Menu.Button ref={menuButtonRef} className="inline-flex w-full justify-center rounded-md bg-indigo-600 bg-opacity-60 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
            Actions
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {actions.map((action, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={action.onClick}
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? action.activeIcon : action.inActiveIcon}
                      {action.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
