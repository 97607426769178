import React from "react";
import { useForm } from "react-hook-form";

// https://www.react-hook-form.com/advanced-usage/

export default function Form({ defaultValues, children, onSubmit }) {
  const methods = useForm({ defaultValues });
  const { handleSubmit, formState: { errors } } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {React.Children.map(children, child => {
        return child?.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                errors: errors[child.props.name],
              }
            })
          : child;
       })}
    </form>
  );
}
