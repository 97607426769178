import Footer from '@/components/common/Footer/Footer'

function DefaultLayout({ children }) {
  return (
    <main>
      {children}
      <Footer></Footer>
    </main>
  )
}

export default DefaultLayout
