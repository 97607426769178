import classNames from '@/core/helpers/classNames'
import { appVersion } from '@/variables/version'

export default function Footer({ className }) {
  return (
    <footer className={classNames(className, 'w-full bg-white')}>
      <div className="mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <h4 className="text-gray-400 text-sm hover:text-gray-500">
            {appVersion}
          </h4>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-sm text-gray-400">
            &copy; 2020 MakerGPT, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
