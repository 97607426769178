import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleFulfilledMessage, handleRejectedMessage } from '@/core/helpers/builderHandler'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'
import { makeRequestWithAuth } from '@/plugins/axios'

const initialState = {
  agents: [],
  paginator: null,
  isLoading: false,
  error: null,
}

export const fetchAgentsBySuperAdmin = createAsyncThunk(
  'agentSlice/fetchAgentsBySuperAdmin',
  (payload) => {
    const url = '/super_admin/agents'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const editAgent = createAsyncThunk('agentSlice/editAgent', (payload) => {
  const url = `/super_admin/agents/${payload.agent_id}`
  const data = {
    name: payload.name,
    balance_credit: parseFloat(parseFloat(payload.credits).toFixed(2)),
  }
  payload.period_end && (data.period_end = payload.period_end)
  payload.kind !== undefined && (data.kind = payload.kind)

  const options = {
    data: data,
    method: 'PUT',
  }

  return makeRequestWithAuth(url, options)
})

export const createNewAgent = createAsyncThunk('agentSlice/createNewAgent', (payload) => {
  const url = `/super_admin/agents`
  const data = {
    name: payload.name,
    balance_credit: parseFloat(parseFloat(payload.credits).toFixed(2)),
  }
  payload.period_end && (data.period_end = payload.period_end)
  payload.kind !== undefined && (data.kind = payload.kind)
  const params = {}

  const options = {
    data: data,
    method: 'POST',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const fetchAgentsByScrollingDown = createAsyncThunk(
  'agentSlice/fetchAgentsByScrollingDown',
  (payload) => {
    const url = '/super_admin/agents'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

const agentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Agent
    builder.addCase(fetchAgentsBySuperAdmin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAgentsBySuperAdmin.fulfilled, (state, action) => {
      const name = action.meta.arg?.name // name from request query params
      state.isLoading = false
      state.agents = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        name: name,
      }
    })
    builder.addCase(fetchAgentsBySuperAdmin.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Scroll
    builder.addCase(fetchAgentsByScrollingDown.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAgentsByScrollingDown.fulfilled, (state, action) => {
      state.isLoading = false
      const newData = action.payload.data
      state.agents = [...state.agents, ...newData]
      state.paginator = action.payload.paginator
    })
    builder.addCase(fetchAgentsByScrollingDown.rejected, (state) => {
      state.isLoading = false
    })

    // Create Agent
    builder.addCase(createNewAgent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewAgent.fulfilled, (state, action) => {
      state.isLoading = false
      const createdAgent = action.payload.data
      state.agents.unshift(createdAgent)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewAgent.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Agent
    builder.addCase(editAgent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editAgent.fulfilled, (state, action) => {
      state.isLoading = false
      handleFulfilledMessage(state, action)
    })
    builder.addCase(editAgent.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })
  },
})

export const selectAgents = (state) => {
  return {
    agents: state.agent.agents,
    paginator: state.agent.paginator,
    isLoading: state.agent.isLoading,
  }
}
export default agentSlice.reducer
