import removeUnderscore from '@/core/helpers/removeUnderscore'

import InputError from '@/components/common/forms/InputError'
import classNames from '@/core/helpers/classNames'

function InputGroup({ className, name, errors, children }) {
  if (name === 'id') return <></>
  return (
    <div className={className}>
      <label htmlFor={name} className="capitalize block text-sm font-medium text-gray-700">
        {removeUnderscore(name)}
      </label>
      <div className={classNames(className, "mt-1")}>
        {children}

        <div className="h-2">
          {errors && <InputError message={errors.message || errors.type} />}
        </div>
      </div>
    </div>
  )
}

export default InputGroup
