import { handleAPIError } from '@/plugins/axiosHandler'
import axios from 'axios'
import Cookies from 'js-cookie'

//config base axios's API
const api_v1 = axios.create({
  baseURL: `${process.env.REACT_APP_MAKER_GPT_BE_URL}/api/v1`,
})

//Before each request, Axios will add the token into the config
api_v1.interceptors.request.use(
  (config) => {
    // Bearer needed for the backend
    const sadminAuthToken = Cookies.get('sadminAuthToken')
    if (sadminAuthToken) {
      config.headers['X-Authentication-Token'] = sadminAuthToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const no_auth_api = axios.create({
  baseURL: `${process.env.REACT_APP_MAKER_GPT_BE_URL}/api/v1`,
})

export function makeRequestWithoutAuth(url, options) {
  return no_auth_api(url, options)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return handleAPIError(error)
    })
}
export function makeRequestWithAuth(url, options) {
  return api_v1(url, options)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return handleAPIError(error)
    })
}

export default api_v1
