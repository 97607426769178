import { useLayoutEffect, useRef } from 'react';

function useOnUpdateEffect(callback, dependencies) {
  const isMounted = useRef(false);

  useLayoutEffect(() => {
    // Skip running the effect on mount
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      return callback();
    }
  }, dependencies);
}

export default useOnUpdateEffect;