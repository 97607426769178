import App from '@/App'
import { StyleProvider } from '@ant-design/cssinjs'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import '@/css/index.css'

import store from '@/stores/store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <StyleProvider hashPriority="high">
    <Provider store={store}>
      <App />
    </Provider>
  </StyleProvider>
  // </React.StrictMode>
)
