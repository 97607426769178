import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@/components/common/buttons/Button'
import FormInput from '@/components/common/forms/FormInput'
import { signIn } from '@/stores/authenticatorSlice'
import { useState } from 'react'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

export default function Login() {
  const {
    register,
    handleSubmit: handleValidateBeforeSubmit,
    formState: { errors },
  } = useForm()
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.authenticator.isLoading)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const handleSignIn = (data) => {
    const { email, password } = data
    dispatch(signIn({ email, password }))
  }

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-5">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="MakerGPT"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
            <form
              onSubmit={handleValidateBeforeSubmit(handleSignIn)}
              className="space-y-6"
              action="#"
              method="POST"
            >
              <FormInput name={'email'} errors={errors.email}>
                <input
                  id="email"
                  name="email"
                  autoComplete='email'
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
              </FormInput>
              <FormInput className="relative" name={'password'} errors={errors.password}>
                <input
                  id="password"
                  name="password"
                  type={showNewPassword ? 'text' : 'password'}
                  autoComplete="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters long',
                    },
                  })}
                />
                 <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <EyeIcon className="h-4 w-4 text-gray-400 mb-2" aria-hidden="true" />
                  ) : (
                    <EyeOffIcon className="h-4 w-4 text-gray-400 mb-2" aria-hidden="true" />
                  )}
                </div>
              </FormInput>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className=" text-sm">
                  <Link to="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <Button isLoading={isLoading} type={'submit'} btnName="Sign In" />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
