import { DocumentReportIcon, HomeIcon, KeyIcon, TagIcon, UserGroupIcon, UserIcon } from '@heroicons/react/solid'
import AgentPage from '@/pages/agent/AgentPage'
import DashboardPage from '@/pages/dashboard/DashboardPage'
import Login from '@/pages/login/Login'
import ManagerPage from '@/pages/manager/ManagerPage'
import AdminLayout from '@/layouts/adminLayout/AdminLayout'
import CredentialPage from '@/pages/credential/CredentialPage'
import TagPage from '@/pages/tag/TagPage'
import ProjectPage from '@/pages/project/ProjectPage'

/**
 * layout: (optional, default: DefaultLayout)
 */
const publicRoutes = [
  { path: '/superadmin/sign-in', component: Login, shouldRedirectIfLoggedin: true, name: 'Login' },
]

const privateRoutes = [
  {
    path: '/superadmin/dashboard',
    component: DashboardPage,
    layout: AdminLayout,
    icon: HomeIcon,
    name: 'Dashboard',
  },
  {
    path: '/superadmin/agents',
    component: AgentPage,
    layout: AdminLayout,
    icon: UserGroupIcon,
    name: 'Agents',
  },
  {
    path: '/superadmin/managers',
    component: ManagerPage,
    layout: AdminLayout,
    icon: UserIcon,
    name: 'Managers',
  },
  {
    path: '/superadmin/projects',
    component: ProjectPage,
    layout: AdminLayout,
    icon: DocumentReportIcon,
    name: 'Projects',
  },
  {
    path: '/superadmin/credentials',
    component: CredentialPage,
    layout: AdminLayout,
    icon: KeyIcon,
    name: 'Credentials',
  },
  {
    path: '/superadmin/tags',
    component: TagPage,
    layout: AdminLayout,
    icon: TagIcon,
    name: 'Tags',
  },
]

const UNAUTHORIZED_REDIRECT_PATH = '/superadmin/sign-in'
const DEFAULT_PATH = '/superadmin/dashboard'

export { publicRoutes, privateRoutes, UNAUTHORIZED_REDIRECT_PATH, DEFAULT_PATH }
