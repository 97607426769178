export function handleAPIError(error) {
    // console.log(error)
    const requestUrl = error.response?.config?.url
  
    if (error.response) {
      // Handle OpenAI-related error
      if (requestUrl.includes('openai.com')) {
        const openAIErrorMessage = JSON.parse(error.response.request.responseText).error.message
        return Promise.reject(openAIErrorMessage)
      }
  
      // Handle backend error
      return Promise.reject(JSON.stringify(error.response.data))
    }
    
    if (error.request) {
      console.log(error.request);
      // The client never received a response, and the request was never left
      if (error.message === 'Network Error') throw new Error('Server Back end is Down!')
    }
  
    // Anything else
    console.log('Error', error.message)
    return Promise.reject(error)
  }