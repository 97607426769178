/* This example requires Tailwind CSS v2.0+ */
import { HomeIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { DEFAULT_PATH } from '@/routes/index'

export default function Breadcrums() {
  const location = useLocation()

  // Extract the current page from the URL
  const segments = location.pathname.split('/').filter((segment) => segment !== '')
  const startIndex = segments.indexOf('superadmin') + 1

  const pages = segments.slice(startIndex).map((segment, index) => {
    const href = `/superadmin/${segments.slice(startIndex, startIndex + index + 1).join('/')}`
    const current = index === segments.length - 1

    return {
      name: segment,
      href,
      current,
    }
  })

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="bg-white rounded-md px-2 md:px-6 flex space-x-4">
        <li className="flex">
          <div className="flex items-center">
            <Link to={DEFAULT_PATH} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={page.href}
                className="capitalize ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
