import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { handleFulfilledMessage, handleRejectedMessage } from '@/core/helpers/builderHandler'
import { mapPaginationFormat } from '@/core/helpers/mapPaginationFormat'

import { makeRequestWithAuth, makeRequestWithoutAuth } from '@/plugins/axios'
import dayjs from 'dayjs'
import { removeZeroValues } from '@/core/helpers/utils'

const initialState = {
  credentials: [],
  paginator: null,
  isLoading: false,
  error: null,
  isCredentialsFetched: false,
  selectedDates: []
}

export const fetchCredentialsBySuperAdmin = createAsyncThunk(
  'credentialSlice/fetchCredentialsBySuperAdmin',
  (payload) => {
    const url = '/super_admin/credentials'
    const data = null
    const params = mapPaginationFormat({ paginator: payload })
    const options = {
      method: 'GET',
      params,
      data,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const editCredential = createAsyncThunk('credentialSlice/editCredential', (payload) => {
  const url = `/super_admin/credentials/${payload.id}`
  const data = removeZeroValues(payload)
  const params = {}

  const options = {
    data: data,
    method: 'PUT',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const saveCredential = createAsyncThunk('credentialSlice/saveCredential', (payload) => {
  const url = `/super_admin/credentials/${payload.id}`
  const data = payload
  const params = {}

  const options = {
    data: data,
    method: 'PUT',
    params,
  }

  return makeRequestWithAuth(url, options)
})

export const createNewCredential = createAsyncThunk(
  'credentialSlice/createNewCredential',
  (payload) => {
    const url = `/super_admin/credentials`
    const data = { key: payload.key, status: payload.status, note: payload.note }
    const params = {}

    const options = {
      data: data,
      method: 'POST',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const deleteCredential = createAsyncThunk(
  'credentialSlice/deleteCredential',
  (payload) => {
    const url = `/super_admin/credentials/${payload.id}`
    const data = null
    const params = {}

    const options = {
      data: data,
      method: 'DELETE',
      params,
    }

    return makeRequestWithAuth(url, options)
  }
)

export const fetchOpenAIAccountPlanCredit = createAsyncThunk('credentialSlice/fetchOpenAIAccountPlanCredit', ({ apiKey }) => {
  const url = `https://api.openai.com/dashboard/billing/subscription`
  const headers = {
    "authorization": `Bearer ${apiKey}`
  }

  const options = {
    method: 'GET',
    headers
  }

  return makeRequestWithoutAuth(url, options)
})
export const fetchOpenAITotalCreditUsed = createAsyncThunk('credentialSlice/fetchOpenAITotalCreditUsed', ({ apiKey, selectedDates }) => {
  // You can only request a maximum of 100 days per request
  let [startDate, endDate] = selectedDates
  startDate = !startDate ? dayjs().startOf('month').format('YYYY-MM-DD') : startDate;
  endDate = !endDate ? dayjs().startOf('month').add(1, 'month').format('YYYY-MM-DD') : endDate;
  const url = `https://api.openai.com/dashboard/billing/usage?start_date=${startDate}&end_date=${endDate}`
  const headers = {
    "authorization": `Bearer ${apiKey}`
  }

  const options = {
    method: 'GET',
    headers
  }

  return makeRequestWithoutAuth(url, options)
})


const credentialSlice = createSlice({
  name: 'credentialSlice',
  initialState,
  reducers: {
    concat: (state, action) => {
      state.credentials = state.credentials.concat(action.payload)
    },
    updateCredentialsState: (state, action) => {
      const updatedCredentials = action.payload;
      state.credentials = updatedCredentials
    },
    updateSelectedCredentialState: (state, action) => {
      const updated = action.payload
      const index = state.credentials.findIndex(credential => credential.id === action.payload.id)
      if (index !== -1) {
        state.credentials[index] = updated
      }
    },
    updateSelectedDates: (state, action) => {
      const selectedDates = action.payload;
      state.selectedDates = selectedDates
    },
  },
  extraReducers: (builder) => {
    // Get Credential
    builder.addCase(fetchCredentialsBySuperAdmin.pending, (state) => {
      state.isLoading = true
      state.isCredentialsFetched = false
    })
    builder.addCase(fetchCredentialsBySuperAdmin.fulfilled, (state, action) => {
      const name = action.meta.arg?.name // name from request query params
      state.isLoading = false
      state.credentials = action.payload.data
      state.paginator = {
        ...action.payload.paginator,
        name: name,
      }
      state.isCredentialsFetched = true
    })
    builder.addCase(fetchCredentialsBySuperAdmin.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
      state.isCredentialsFetched = false
    })

    // Create Credential
    builder.addCase(createNewCredential.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createNewCredential.fulfilled, (state, action) => {
      state.isLoading = false
      const createdCredential = action.payload.data
      state.credentials.unshift(createdCredential)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(createNewCredential.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // Edit Credential
    builder.addCase(editCredential.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editCredential.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedCredential = action.payload.data
      const credentialIndex = state.credentials?.findIndex(
        (credential) => credential.id === updatedCredential.id
      )
      if (credentialIndex !== -1) {
        state.credentials[credentialIndex] = updatedCredential
        handleFulfilledMessage(state, action)
      }
    })
    builder.addCase(editCredential.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // SAVE
    builder.addCase(saveCredential.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(saveCredential.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(saveCredential.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // DELETE
    builder.addCase(deleteCredential.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteCredential.fulfilled, (state, action) => {
      state.isLoading = false
      state.credentials = state.credentials.filter(credential => credential.id !== action.meta.arg.id)
      handleFulfilledMessage(state, action)
    })
    builder.addCase(deleteCredential.rejected, (state, action) => {
      state.isLoading = false
      handleRejectedMessage(state, action)
    })

    // OPENAI
    // builder.addCase(fetchOpenAITotalCreditUsed.rejected, (state, action) => {
    //   handleRejectedMessage(state, action)
    // })
    builder.addCase(fetchOpenAITotalCreditUsed.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOpenAITotalCreditUsed.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(fetchOpenAITotalCreditUsed.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export const selectCredentials = (state) => {
  return {
    credentials: state.credential.credentials,
    paginator: state.credential.paginator,
    isLoading: state.credential.isLoading,
    isCredentialsFetched: state.credential.isCredentialsFetched,
    selectedDates: state.credential.selectedDates,
  }
}
export const { concat, updateCredentialsState, updateSelectedDates, updateSelectedCredentialState } = credentialSlice.actions
export default credentialSlice.reducer
