import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { timestampFormat } from '@/core/helpers/dayjs'
import { convertArrObjToArrStr } from '@/core/helpers/utils'

import AntdTable from '@/components/antd/table/AntdTable'

import { fetchProjectsBySuperAdmin, selectProjects, setRowsSelected } from '@/stores/projectSlice'

import { TAG_ACTIONS } from '@/variables/tags'
import { FORM_ACTIONS } from '@/variables/formActions'
import ReadmoreBtn from '@/components/common/buttons/ReadmoreBtn'
import { PRIVACY_SETTINGS, PrivacySettingsTrans } from '@/variables/project'

function ProjectTable({ handleActions }) {
  let { projects, paginator, isLoading } = useSelector(selectProjects)
  const dispatch = useDispatch()

  // 1. user clicked sort || page button
  const handleTableChange = (pagination, filters, sorter) => {

    // handle filter privacy_setting
    switch (filters?.privacy_setting?.length) {
      case 2:
        filters = {}
        break;
      case 1:
        filters.privacy_setting = filters.privacy_setting.pop()
        break;
      default:
        break;
    }

    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: sorter?.field,
      order: sorter?.order,
    }
    dispatch(fetchProjectsBySuperAdmin(paginator))
  }

  useEffect(() => {
    dispatch(fetchProjectsBySuperAdmin(paginator)) // Fetch for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (projectId) => <div key={projectId}>{projectId}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      // sorter: true,
      render: (name) => <div key={name}>{name}</div>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      // sorter: true,
      render: (description) => <ReadmoreBtn text={description} limit={30} />,
    },
    {
      title: 'Visibility',
      dataIndex: 'privacy_setting',
      filterMode: 'tree',
      filterSearch: true,
      filters: [
        {
          text: 'Public',
          value: 0,
        },
        {
          text: 'Private',
          value: 1,
        },
      ],
      render: (privacy_setting, record) => <div className="">{PrivacySettingsTrans(privacy_setting)}</div>,
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      // sorter: true,
      render: (createdAt) => <div key={createdAt}>{timestampFormat(createdAt)}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_EDIT_MODAL, record)}
          />
          {/* <DeleteOutlined
            className="text-indigo-500 text-lg"
            onClick={() => handleColumnClick(FORM_ACTIONS.DELETE, record)}
          /> */}
        </Space>
      ),
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(
        setRowsSelected(convertArrObjToArrStr({ objectList: selectedRows, fieldName: 'id' }))
      )
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  return (
    <AntdTable
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      dataSource={projects}
      paginator={paginator}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey="id"
    />
  )
}

export default ProjectTable
