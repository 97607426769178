import { EditOutlined } from '@ant-design/icons'
import { Space, Table, Tooltip } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { timestampFormat } from '@/core/helpers/dayjs'
import AntdTable from '@/components/antd/table/AntdTable'
import FilterSearch from '@/components/antd/table/FilterSearch'
import RelativeTime from '@/components/dayjs/RelativeTime'
import { fetchAgentsBySuperAdmin, selectAgents } from '@/stores/agentSlice'
import { FORM_ACTIONS } from '@/variables/formActions'

function AgentTable({ handleActions }) {
  let { agents, paginator, isLoading } = useSelector(selectAgents)
  const dispatch = useDispatch()

  // 1. user clicked sort || page button
  const handleTableChange = (pagination, filters, sorter) => {
    // change the field agent_id to id
    const modifiedSorter =
      sorter && sorter.field === 'agent_id' ? { ...sorter, field: 'id' } : sorter
    // overwrites the existing tableParams object with the new values
    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: modifiedSorter?.field,
      order: modifiedSorter?.order,
    }
    dispatch(fetchAgentsBySuperAdmin(paginator))
  }

  useEffect(() => {
    dispatch(fetchAgentsBySuperAdmin(paginator)) // Fetch for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'AgentID',
      dataIndex: 'agent_id',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (text) => <div key={text}>{text}</div>,
    },
    {
      title: 'Manager Emails',
      dataIndex: 'manager_emails',
      ...FilterSearch({ dataIndex: 'manager_emails', nestedFieldNames: ['managers', 'email'] }),
      render: (_, { managers }) => (
        <>
          {managers &&
            managers.map((manager) => {
              return <div key={manager.email}>{manager.email}</div>
            })}
        </>
      ),
    },
    {
      title: 'Plan',
      dataIndex: 'package',
      // sorter: true,
      render: (_, record) => {
        const plan = record.package?.kind_text
        return <div key={plan}>{plan || 'None'}</div>
      },
    },
    {
      title: 'Credits',
      dataIndex: 'balance_credit',
      sorter: true,
      render: (credits) => <div key={credits}>{credits ? Number(credits).toFixed(2) : 0}</div>,
    },
    {
      title: 'Period end',
      dataIndex: 'period_end',
      sorter: false,
      render: (_, record) => {
        const day = record.package?.end_date
        return day ? (
          <div key={day}>{timestampFormat(day || new Date().getTime() / 1000)}</div>
        ) : (
          'Never expire'
        )
      },
    },
    {
      title: 'Project Counts',
      dataIndex: 'projects_count',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      render: (created_at) => <div key={created_at}>{timestampFormat(created_at)}</div>,
      // sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              className="text-indigo-500 text-lg"
              onClick={() => handleActions(FORM_ACTIONS.TO_SHOW_EDIT_MODAL, record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <AntdTable
      columns={columns}
      dataSource={agents}
      paginator={paginator}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey="agent_id"
    />
  )
}

export default AgentTable
