import Cookies from 'js-cookie'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import PageNotFound from '@/components/common/404/PageNotFound'
import PrivateRoute from '@/components/common/routes/privateRoute/PrivateRoute'
import PublicRoute from '@/components/common/routes/publicRoute/PublicRoute'
import DefaultLayout from '@/layouts/defaultLayout/DefaultLayout'
import { DEFAULT_PATH, privateRoutes, publicRoutes } from '@/routes/index'
import { validateAuthTokenMiddleware } from '@/stores/authenticatorSlice'

function App() {
  const [shouldShowRoutes, setShouldShowRoutes] = useState(false)
  const { isLoggedIn } = useSelector((state) => state.authenticator)
  const dispatch = useDispatch()

  // If layout not specify, DefaultLayout will be used instead
  const getLayout = (route) => {
    if (route.layout) {
      return route.layout
    } else if (route.layout === null) {
      return Fragment
    } else {
      return DefaultLayout
    }
  }

  // Wait for the validateAuthTokenMiddleware to finish
  // then we render the page
  useEffect(() => {
    const validateToken = async () => {
      if (Cookies.get('sadminAuthToken')) {
        await dispatch(validateAuthTokenMiddleware())
        setShouldShowRoutes(true)
      } else {
        setShouldShowRoutes(true)
      }
    }
    validateToken()
  }, [dispatch])

  const renderRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to={DEFAULT_PATH} />} />

          {/* Public Routes */}
          {publicRoutes.map((route) => {
            const Page = route.component
            const Layout = getLayout(route)

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PublicRoute
                    title={route.name}
                    isLoggedIn={isLoggedIn}
                    shouldRedirectIfLoggedin={route.shouldRedirectIfLoggedin}
                  >
                    <Layout>
                      <Page />
                    </Layout>
                  </PublicRoute>
                }
              />
            )
          })}

          {/* Private Routes */}
          {privateRoutes.map((route) => {
            const Page = route.component
            const Layout = getLayout(route)

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PrivateRoute title={route.name} isLoggedIn={isLoggedIn}>
                    <Layout>
                      <Page />
                    </Layout>
                  </PrivateRoute>
                }
              />
            )
          })}

          {/* Redirect to page not found if not exist */}
          <Route path="*" Component={PageNotFound} />
        </Routes>
      </Router>
    )
  }

  return shouldShowRoutes ? renderRoutes() : <></>
}

export default App
