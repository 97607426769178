import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import HeaderNav from '@/layouts/components/HeaderNav/HeaderNav'
import LeftSidebar from '@/layouts/components/LeftSidebar/LeftSidebar'

import { privateRoutes } from '@/routes/index'

import { signOut } from '@/stores/authenticatorSlice'
import Footer from '@/components/common/Footer/Footer'

function AdminLayout({ children }) {
  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const currentPage = location.pathname.split('/').pop()
  // Add current page field for css style
  const updatedPrivateRoutes = privateRoutes.map((route) => ({
    ...route,
    current: route.path.includes(currentPage),
  }))

  // handle all User Navigations On the headre
  const userNavigations = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#', isSignOutTab: true },
  ]
  const dispatch = useDispatch()
  const handleUserNavigationClick = (navigationItem) => {
    if (navigationItem.isSignOutTab) {
      dispatch(signOut())
    }
  }

  return (
    <div>
      <LeftSidebar
        routes={updatedPrivateRoutes}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <div className="md:pl-64">
        <div className="h-screen md:px-8 mx-auto flex flex-col sm:px-0">
          {/* Right Header Navbar */}
          <HeaderNav
            onNavigationClick={handleUserNavigationClick}
            setSidebarOpen={setSidebarOpen}
            userNavigations={userNavigations}
          />

          <main className="flex-1">
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-0">
                {/* Replace with your content */}
                {children}
                {/* /End replace */}
              </div>
            </div>
          </main>
          <Footer className="self-end"></Footer>
        </div>
      </div>
      
    </div>
  )
}

export default AdminLayout
