export const CredentialStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
}

export const CREDENTIAL_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete'
}

export function CredentialStatusTrans(credential = 0) {
  return (
    {
      0: 'Active',
      1: 'InActive',
    }[credential] || ''
  );
}

export const CREDENTIAL_STATUS = [
  {
    value: '0',
    label: 'Active' 
  },
  {
    value: '1',
    label: 'InActive' 
  },
]


